import React, { useState, useEffect  } from "react";

import Layout from "../../components/layout-default";
import teamsStyles from "../../components/teams.module.scss";
import Nav from "../../components/nav";
import HeroVideo from "../../components/video";
import homeStyles from "../../components/home.module.scss";
import TeamImg from "../../images/team.jpeg";
import promo from "../../../src/static/placement-promo2.mp4";
import "../../styles/layout.scss";
import "../../styles/teams.scss";
import Breadcrumbs from "../../components/breadcrumbs";
import SalsaClasses from "../../components/SalsaClasses";
import FAQSalsa from "../../components/FAQSalsa";
import TestimonialSalsaPage from "../../components/TestimonialSalsaPage";

import MindBodyWidget from "../../components/mindbody";
import ErrorBoundary from "../../components/ErrorBoundary";

const crumbs = [
  {
    label: "Home",
    link: "",
  },
  {
    label: "Classes",
    link: "classes",
  },
  {
    label: "Salsa",
    link: "/classes/salsa-dance-classes-in-orange-county-ca/",
  },
];
const SalsaClassesPage = () => {
  const [activeModal, setActiveModal] = useState(false);
  
  useEffect(() => {
      const timer = setInterval(() => {
        var mbScript =
          typeof document !== `undefined`
            ? document.querySelector(
                `[data-link-class=healcode-contract-text-link] .healcode-link`
              )
            : undefined;
        if (mbScript) {
          clearInterval(timer);
          const wrapper =
            typeof document !== `undefined`
              ? document.querySelector(".contract-wrapper")
              : undefined;
          wrapper.classList.add("is-active");
        }
      }, 1000);
    });
  return (
    <>
      <Layout
        title="Best Salsa Dancing Classes in Orange County | RF Dance"
        description="Join RF Dance for exciting salsa dancing classes in Orange County! Explore couple salsa lessons for all levels, from beginners to experts & enjoy the excitement!"
        pathname="classes/salsa-dance-classes-in-orange-county-ca/"
        img={TeamImg}
        className="services_page"
      >
        <div className={homeStyles.hero}>
          <div className={homeStyles.heroHead}>
            <Nav isHome />
          </div>
          <div className={homeStyles.heroBody}>
            <div className="container">
              <div className="text-center">
                <div className={homeStyles.heroContent}>
                  <h2 className={`title`}>
                    <span className={teamsStyles.heroHeader}>
                      <span className={teamsStyles.titleRed}>RF</span>
                      <span className={teamsStyles.titleWhite}>TEAMS</span>
                    </span>
                    <span className={teamsStyles.heroTitle}>
                      Join us for team placement classes
                    </span>
                  </h2>
                </div>
                <div className={homeStyles.heroContent}>
                  <HeroVideo
                    isTeams
                    promo={promo}
                    secondaryLinkFunction={() => {
                      setActiveModal(true);
                    }}
                    secondaryBtnType="button"
                    secondaryBtnText="Save your spot"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <section className={`${teamsStyles.content} content thirds`}>
          <div className={`${teamsStyles.contentBlock} content-block swip_ele_mb`}>
           <div className="container">
            <Breadcrumbs crumbs={crumbs} /> 
            <SalsaClasses />
            <div id="mindbodywidget_custom">
              <ErrorBoundary>
                <MindBodyWidget
              id="mb-script-02"
              type="schedules"
              widgetPartner="object"
              widgetId="d419193709b0"
              widgetVersion="1"
            />
               </ErrorBoundary>
            </div>
            <TestimonialSalsaPage />
            <FAQSalsa />
          </div>
          </div>
        </section>
        <style jsx="jsx">{`
        .contract-wrapper {
          text-align: center;
          padding: 3em 0;
          background-color: #fff;
          display: none;
        }
        .contract-wrapper a.healcode-link{
          background-color: #209cee;
          padding: 1em 2em;
          border-radius: 27px;
          color: #fff;
          border: 1px solid #209cee;
        }
        .contract-wrapper a:hover {
          background-color: #fff;
          color: #209cee;
        }
        .contract-header,
        .contract-body {
          display: none;
        }
        .contract-wrapper.is-active {
          display: block;
        }
        .contract-wrapper.is-active .contract-header,
        .contract-wrapper.is-active .contract-body {
          display: block;
        }
        .contract-body {
          max-width: 80%;
          margin: 0 auto 3em;
        }
        .services_content h2 {
          margin-top: 0px;
          line-height: 1.5;
            margin-bottom: 10px !important;
        }
      `}</style>
      </Layout>
      {activeModal && (
        <Modal activeModal={activeModal} setActiveModal={setActiveModal} />
      )}
    </>
  );
};

export default SalsaClassesPage;

const Modal = ({ setActiveModal }) => {
  return (
    <div className={`${teamsStyles.modal} modal`}>
      <div className="modal-background" onClick={() => setActiveModal(null)} />
      <div className={`${teamsStyles.modalCard} modal-card`}>
        <div className={`${teamsStyles.modalBody} modal-card-body`}>
          <iframe
            className={teamsStyles.modalIframe}
            src="https://slicktext.com/widget/v2/19d913280217b4302a3351a81e056bf0"
            title="RF Placement Classes Notifications"
          ></iframe>
        </div>
      </div>
      <button
        onClick={() => {
          setActiveModal(null);
        }}
        className="modal-close is-large"
        arialabel="close"
      ></button>
    </div>
  );
};
