import React from "react";
import "../styles/classes.scss";
import SalsaBigInt from "../images/salsa-1.jpg";
import SalsaIntAdv from "../images/salsa-2.jpg";
import SalsaIntthreee from "../images/Salsa-3-new.png";
import { Link } from "gatsby";

const SalsaClasses = () => (
  <div className="inner_content_swipe">
    <div className="classes-container">
      <div className="classes-content">
        <img src={SalsaBigInt} alt="" width={300} height={200} />
        <div className="services_content">
          <h1>Salsa Dancing Classes Orange County</h1>
          <p>
            Would you love to immerse yourself in the rhythmic vibe of Latin
            American culture? Have you wished to learn the basics of Salsa? If
            yes, then what else could be better than enrolling in salsa dancing
            classes in Orange County. At RF Dance, we pride ourselves on
            creating a welcoming and supportive community of dancers who share a
            love for salsa and a passion for learning. With convenient class
            times and locations, there's never been a better time to join us and
            start your salsa dancing journey.
          </p>
          <p>
            At <Link to="/">RF Dance</Link>, we present the finest of personalized beginner salsa
            classes for all age groups, ensuring a friendly and energetic vibe.
            We have to mention that it’s just more than the lessons - a great
            opportunity to stay fit, make friends, meet new like minded people,
            and discover a unique passion.
          </p>
          <p>
            So we welcome you to the ultimate world of salsa dancing classes in
            Orange County! Whether you're a beginner looking to learn the basics
            or an experienced dancer looking to take your skills to the next
            level, there are plenty of options available to you in our vibrant
            and exciting catalog of RF Dance's salsa dance lessons.
          </p>
        </div>
      </div>

      <div className="classes-content">
        <img src={SalsaIntAdv} alt="" width={300} height={200} />
        <div className="services_content">
          <h2>Fall in Love with Salsa: Couples Salsa Dance Lessons</h2>
          <p>
            RF Dance - the premier dance studio for salsa and <Link to="/classes/bachata-dance-classes-in-orange-county-ca">bachata dancing classes in Orange County!</Link> Our experienced and passionate instructors are dedicated to helping students of all levels discover the joy and beauty of couple salsa dancing lessons.
          </p>
          <p>
            Through our couples salsa dance lessons, you will get to work on
            coordination, balance, and fitness levels as a duo. While having the
            fun of learning a new thing, our expert trainers ensure to keep up
            with the confidence you might need. So why not skip for a while from
            the routine hustle and re-charge yourselves with an outstanding
            passion? Our couple salsa lessons are a thrilling journey of self
            discovery, balance, and self expression with your partner at all
            levels. Currently, RF Dance caters to all levels of salsa dancing
            lessons from beginners to basic, intermediate, and premium.
          </p>
          <p>
            Whether you're a complete beginner or an experienced dancer looking
            to improve your skills, we have a class that's perfect for you. Our
            classes are designed to be fun and engaging, while also challenging
            you to reach your full potential as a dancer.
          </p>

          <p>
            So why wait? Sign up for a salsa dancing class today and start
            enjoying all the fun and excitement that Orange County has to offer!
          </p>
        </div>
      </div>

      <div className="classes-content third_area">
        <img src={SalsaIntthreee} alt="" width={300} height={200} />
        <div className="services_content">
          <h2>Why RF Dance for Salsa Classes in Orange County</h2>
          <p>We are your ideal destination for Salsa classes in Orange County. Whether you’re a beginner or a pro looking to hone your skills or someone who wants to sizzle the chemistry between you and your partner through couples Salsa classes, we’re your ultimate dance studio. From non-experienced/beginner to intermediate and advanced dancers, RF Dance has teams to make Salsa enjoyable for everyone. We are your ideal destination for Salsa classes in Orange County!
          </p>
          <p>At RF Dance, we believe that Salsa is not just a dance; it's an exhilarating experience that brings people together, ignites passion, and adds a dash of excitement to life. Whether you're taking your very first steps into the world of dance or you're a seasoned pro looking to fine-tune your moves, our dance studio is here to cater to all your Salsa needs. And, even if you wish to sizzle the chemistry between you and your partner, we have couples Salsa classes for you. What sets us apart is our commitment to making Salsa an enjoyable and inclusive experience for everyone.</p>
          <p>Our experienced and passionate dance teams are dedicated to providing a warm and welcoming environment where you can learn, grow, and dance your heart out. We understand that every dancer is unique, and we tailor our classes to accommodate various skill levels and learning paces. So, get ready to groove to the infectious rhythms of Salsa music, feel the adrenaline rush, and immerse yourself in the world of dance. Remember , at RF Dance, we are not just about teaching dance; we're about creating memorable experiences that will keep you coming back for more.</p>
          <p>Join us today and let's dance the Salsa way!</p>
        </div>
      </div>
    </div>
  </div>
);

export default SalsaClasses;
