import React, { useState } from 'react';

// Individual FAQ item component
const FAQItem = ({ question, answer }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleOpen = () => {
        setIsOpen(!isOpen);
    };
    const faqclass = isOpen ? 'faqs active' : 'faqs';

    const faqContentClass = isOpen ? 'faqs_content active_active' : 'faqs_content';

    return (
        <div className={faqclass}>
            <h3 itemProp="name" className='toggle_title' onClick={toggleOpen}>
                {question}
                <span className="arrow"></span>
            </h3>
            <div className={faqContentClass} itemScope itemType="https://schema.org/Answer" itemProp="acceptedAnswer">
                <p itemProp="text">{answer}</p>
            </div>
        </div>
    );
};

// FAQ list component
const FAQList = ({ items }) => {
    return (
         <div itemScope itemType="https://schema.org/FAQPage">
                {items.map((item, index) => (
                    <div key={index} itemProp="mainEntity" itemScope itemType="https://schema.org/Question">
                    <FAQItem key={index} question={item.question} answer={item.answer} />
                    </div>
                ))}
        </div>
    );
};

// Main App component with provided FAQ data
const FAQSala = () => {
    const faqs = [
        {
            question: "What is the most popular salsa dance style?",
            answer: "The most popular salsa dance style worldwide is the \"Cuban Salsa\" or \"Casino\" style, renowned for its infectious energy, intricate footwork, and vibrant social interactions that bring communities together on the dance floor."
        },
        {
            question: "WHAT IS THE PURPOSE OF THE SALSA DANCING CLASSES?",
            answer: "To help you become a better dancer. Apart from that, it depends on your goal. For example, if you are a beginner, Salsa dance classes can help you learn the basics. On the other hand, in case you are an intermediate dancer, the class can help hone your skills."
        },
        {
            question: "WHAT IS THE SALSA DANCE CLASS COST?",
            answer: "The cost for Salsa dance classes depend on a variety of factors including the level of dance you’ll enroll in and many more. Generally, investing in salsa classes opens doors to a transformative experience, enabling students to embrace the captivating rhythms and intricate movements that define this enchanting dance style."
        },
        {
            question: "HOW LONG DOES IT TAKE TO LEARN SALSA DANCE?",
            answer: "The duration it takes to learn Salsa varies based on factors such as an individual's previous dance experience, Salsa dance classes they join, commitment, and frequency of practice. On average, beginners can expect to achieve basic proficiency within a few months."
        },
        {
            question: "Where is salsa dance most popular?",
            answer: "Salsa dance's popularity transcends borders, finding its vibrant heart in Latin American countries like Cuba, Puerto Rico, and Colombia. Beyond its roots, salsa has enhanced the global dance community, thriving in major cities worldwide, such as New York, Los Angeles, Miami, and beyond, where dancers come together to celebrate life through electrifying dance connections."
        },
        {
            question: "What is special about salsa dance?",
            answer: "The allure of salsa dance lies in its seamless blend of cultural diversity, infectious music, and captivating movements that ignite a sense of celebration and camaraderie among dancers. It embodies a unique ability to create an exuberant ambiance where individuals of all backgrounds can unite, fostering a dynamic and joyous experience that transcends language and cultural barriers."
        },
        {
            question: "Is salsa a hard dance?",
            answer: "While mastering salsa dance demands dedication and practice, the reward is a gratifying journey that empowers dancers with newfound skills, rhythmical prowess, and self-assurance. As beginners overcome challenges, they unlock a world of rhythmic possibilities, honing their abilities to effortlessly flow on the dance floor with finesse and flair."
        },
        {
            question: "Is salsa dancing always with a partner?",
            answer: "Salsa dancing encompasses a versatile range of expressions, accommodating both partnered and solo experiences. While traditional salsa often involves enchanting partner work, solo salsa, also known as \"solo shines,\" empowers dancers to unleash their creativity, weaving together individual moves and intricate footwork, adding a touch of personal style to their dance repertoire."
        }
    ];

    return (
        <div className="faq_section">
            <h3 className='main_heading'>FAQ</h3>
            <FAQList items={faqs} />
        </div>
    );
};

export default FAQSala;
